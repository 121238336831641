<template>
  <v-row class="match-height">
    <!-- horizontal -->
    <v-col cols="12" md="12">
      <v-card>
        <!-- <v-card-title></v-card-title> -->
        <v-card-text>
          <v-form>
            <v-row>
              <v-col cols="" md="2">
                <!-- <input
                  type="date"
                  v-model="filter.tanggal_mulai"
                  style="border: 1px solid grey"
                  @keydown.enter="optionChange()"
                /> -->
                <v-text-field
                  label="Tanggal Awal *"
                  type="date"
                  v-model="filter.tanggal_mulai"
                  outlined
                  hide-details
                  @keydown.enter="optionChange()"
                />
              </v-col>
              <v-col cols="" md="1"> Sampai </v-col>

              <v-col cols="" md="2">
                <!-- <input
                  type="date"
                  v-model="filter.tanggal_akhir"
                  style="border: 1px solid grey"
                  @keydown.enter="optionChange()"
                /> -->
                <v-text-field
                  label="Tanggal Akhir*"
                  type="date"
                  v-model="filter.tanggal_akhir"
                  outlined
                  hide-details
                  @keydown.enter="optionChange()"
                />
              </v-col>

              <v-col cols="" md="2">
                <v-select
                  label="Jenis Upah"
                  v-model="filter.jenis_upah"
                  :items="jenis_upah"
                  item-text="text"
                  item-value="value"
                  dense
                  outlined
                  @change="optionChange"
                ></v-select>
              </v-col>
              <v-col cols="3">
                <v-btn color="primary" small @click="optionChange()"> Lihat </v-btn>
                <v-btn color="primary" class="ml-2" small @click="cetak"> Cetak </v-btn>
              </v-col>
            </v-row>
          </v-form>
          <br />

          <div id="printMe">
            <table cellpadding="0" cellspacing="0" class="header">
              <tr>
                <td style="width: 20%">
                  <img style="width: 150px; height: auto" :src="require('@/assets/images/logos/logo.png')" />
                </td>
                <td style="width: 80%">
                  <center>
                    <span v-if="kop.kop_1 != ''" style="font-size: 12pt; font-weight: bold"
                      >{{ kop.kop_1 }} <br
                    /></span>
                    <span v-if="kop.kop_2 != ''" style="font-size: 12pt; font-weight: bold"
                      >{{ kop.kop_2 }} <br
                    /></span>
                    <span v-if="kop.kop_3 != ''" style="font-size: 10pt">{{ kop.kop_3 }} ><br /></span>
                    <span v-if="kop.kop_4 != ''" style="font-size: 10pt">{{ kop.kop_4 }} ><br /></span>
                  </center>
                </td>
                <td style="width: 20%">&nbsp;</td>
              </tr>
            </table>
            <h3 style="text-align: center">
              Laporan Jatuh Tempo Pinjaman Jenis Upah {{ filter.jenis_upah }}<br />
              Periode
              {{ formatDate(filter.tanggal_mulai) + ' s/d ' + formatDate(filter.tanggal_akhir) }}
            </h3>
            <table cellpadding="0" cellspacing="0" class="body">
              <thead>
                <tr>
                  <th class="text-left">No</th>
                  <th class="text-left">ID Pinjaman</th>
                  <th class="text-left">Anggota</th>
                  <th class="text-left">Program</th>
                  <th class="text-left">Besar Pinjam</th>
                  <th class="text-left">Angsuran</th>
                  <th class="text-left">Besar Angsuran</th>
                  <th class="text-left">Tgl Tempo</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in desserts" :key="index">
                  <td>{{ index + 1 }}</td>
                  <td>{{ item.id }}</td>
                  <td>{{ item.nama_anggota + ' (' + item.kode_anggota + ')' }}</td>
                  <td>{{ item.jenis_pinjaman }}</td>
                  <td style="text-align: right">{{ new Intl.NumberFormat(['id']).format(item.besar_pinjam) }}</td>
                  <td>
                    {{
                      item.x_angsur +
                      ' ' +
                      (item.jenis_upah != null ? item.jenis_upah.replace('AN', '') : '') +
                      ' Dari ' +
                      item.lama_angsuran +
                      ' ' +
                      (item.jenis_upah != null ? item.jenis_upah.replace('AN', '') : '')
                    }}
                  </td>
                  <td style="text-align: right">
                    {{ new Intl.NumberFormat(['id']).format(parseFloat(item.besar_angsuran) + parseFloat(item.bunga)) }}
                  </td>
                  <td>{{ formatDate(item.tgl_tempo) }}</td>
                </tr>
                <tr>
                  <td style="font-weight: bold; text-align: right" colspan="6">Total</td>
                  <td style="font-weight: bold; text-align: right">
                    {{ new Intl.NumberFormat(['id']).format(footer.total) }}
                  </td>
                  <td style="font-weight: bold"></td>
                </tr>
              </tbody>
            </table>
          </div>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import '@/styles/print.css'
import axios from 'axios'

const apiRoot = process.env.VUE_APP_APIURL
export default {
  data: () => ({
    desserts: [],
    loading: true,
    filter: {
      tanggal_mulai: new Date().toJSON().slice(0, 8).replace(/-/g, '-') + '01',
      tanggal_akhir: new Date().toJSON().slice(0, 10).replace(/-/g, '-'),
      jenis_upah: 'HARIAN',
    },
    jenis_upah: ['HARIAN', 'MINGGUAN', 'BULANAN'],
    footer: {
      total: 0,
    },
    kop: {},
  }),

  computed: {
    token() {
      return this.$store.getters.token
    },
  },

  watch: {},

  created() {
    this.getDataFromApi()
    this.getParameterKop()
  },

  methods: {
    async cetak() {
      // Pass the element id here
      await this.$htmlToPaper('printMe', {})
    },
    getDataFromApi() {
      this.$store.dispatch('loading', true)

      const headers = {
        Authorization: this.token,
      }
      const form = new FormData()
      form.append('tanggal_mulai', this.filter.tanggal_mulai)
      form.append('tanggal_akhir', this.filter.tanggal_akhir)
      form.append('jenis_upah', this.filter.jenis_upah)
      axios
        .post(`${apiRoot}/api/Pinjaman/laporan_jatuhtempo`, form, { headers })
        .then(response => {
          if (response.data.code === 401) {
            this.$store.dispatch('logout', 'Session berakhir!')
          } else if (response.data.code === 200) {
            this.desserts = response.data.data
            this.footer.total = 0
            response.data.data.forEach(element => {
              this.footer.total += parseFloat(element.besar_angsuran) + parseFloat(element.bunga)
            })
          } else {
            alert(response.data.message)
          }
          this.$store.dispatch('loading', false)
        })
        .catch(error => {
          alert(error)
          this.$store.dispatch('loading', false)
        })
    },
    getParameterKop() {
      this.$store.dispatch('loading', true)

      const headers = {
        Authorization: this.token,
      }

      this.$store.dispatch('loading', true)
      axios
        .get(`${apiRoot}/api/Dashboard/getParameterKop`, { headers })
        .then(response => {
          if (response.data.code === 401) {
            this.$store.dispatch('logout', 'Session berakhir!')
          } else if (response.data.code === 200) {
            this.kop = response.data.data.parameter
          } else {
            alert(response.data.message)
          }
          this.$store.dispatch('loading', false)
        })
        .catch(error => {
          alert(error)
          this.$store.dispatch('loading', false)
        })
    },
    optionChange() {
      if (this.filter.tanggal_mulai != '' && this.filter.tanggal_akhir != '') {
        this.getDataFromApi()
      }
    },
  },
}
</script>
